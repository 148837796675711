import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { PageProps, navigate } from 'gatsby';
import {
  inputChangeHandler,
  navigateToNextView,
  isValid,
  handleFetch,
} from '@/utils';

import { BenefitsText, SubText, Underline } from '@/components/generics/styled';
import TextInput from '@/components/generics/textInput';
import Footer from '@/components/generics/footer';
import Button from '@/components/generics/button';
import Header from '@/components/header';
import Layout from '@/components/layout';

type SpacerProps = {
  space: StrNum;
};

const Main = styled.main`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CorrectedBenefitsText = styled(BenefitsText)`
  margin: 24px 16px;
  text-align: left;
`;

const Spacer = styled.div<SpacerProps>`
  height: ${(props) => props.space}px;
`;

const getUserData = async (medidor: string): Promise<any> => {
  const res = await handleFetch(`/${medidor}`);
  return res;
};

const IngresarDatos: React.FC<PageProps> = () => {
  const [medidor, setMedidor] = useState({ value: '', valid: false });
  const [phone, setPhone] = useState({ value: '', valid: false });
  const [email, setEmail] = useState({ value: '', valid: false });
  const [footer, setFooter] = useState(true);
  const requestSent = useRef(false);

  const handleOnBlur = () => {
    setFooter(true);
  };

  const handleOnFocus = () => {
    if (window.innerHeight < 900) {
      setFooter(false);
    }
  };

  const medidorParser = (value: string) => value.substring(0, 11);
  const phoneParser = (value: string) => value.substring(0, 10);

  const handleOnClick = async () => {
    try {
      requestSent.current = true;
      const data = {
        medidor: medidor.value,
        phone: phone.value,
        email: email.value,
      };
      const mideUser = await getUserData(data.medidor);
      navigateToNextView({
        state: { mideUser, formData: data },
      });
    } catch {
      navigate('/error', {
        state: { message: 'No se encontró la cuenta MIDE' },
      });
    }
  };

  return (
    <Layout>
      <Header />
      <Main>
        <CorrectedBenefitsText>
          Completá con tus datos para recargar $50 gratis.
        </CorrectedBenefitsText>
        <TextInput
          type="number"
          label="Número de medidor"
          value={medidor.value}
          onChange={inputChangeHandler(
            setMedidor,
            isValid.medidor,
            medidorParser,
          )}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          infoText="Ingresá los 11 dígitos de tu medidor"
        />
        <Spacer space={15} />
        <TextInput
          type="number"
          label="Número de celular"
          value={phone.value}
          onChange={inputChangeHandler(setPhone, isValid.phone, phoneParser)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          infoText="Con código de área sin el 0 y sin el 15"
        />
        <Spacer space={15} />
        <TextInput
          type="email"
          label="Email"
          value={email.value}
          onChange={inputChangeHandler(setEmail, isValid.email)}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          infoText="Acá te enviaremos el código de recarga."
        />
        <Spacer space={50} />
        <Button
          onClick={handleOnClick}
          disabled={
            !medidor.valid ||
            !phone.valid ||
            !email.valid ||
            requestSent.current
          }
        >
          Continuar
        </Button>
        <SubText>
          Al continuar acepto los&nbsp;
          <Underline>
            <a href="https://auntap.com/terminos-y-condiciones/">
              Términos y Condiciones
            </a>
          </Underline>
        </SubText>
      </Main>
      {footer && <Footer />}
    </Layout>
  );
};

export default IngresarDatos;
